

















import { Component } from 'vue-property-decorator'
import Slide from '@/mixins/Slide.ts'
import { mixins } from 'vue-class-component'
import SlideCarouselItem from '@/components/SlideCarouselItem.vue'
import MediaContent from '@/components/MediaContent.vue'
import KeyFigures from '@/components/KeyFigures.vue'
import ScrollerElement, { ScrollerElementAnimationEvent } from '@/components/ScrollerElement.vue'

@Component({
    components: {
        SlideCarouselItem,
        MediaContent,
        KeyFigures,
        ScrollerElement
    }
})
export default class SlideBasicCarouselItem extends mixins(Slide) {
    $refs!: {
        image: HTMLElement
    }

    get walkerItem() {
        return this.walker.item as SlideWalkerItemBasicCarouselItem
    }

    formatIndex(index: number): string {
        return index < 10 ? '0' + (index + 1) : '' + (index + 1)
    }

    populateEnterX({ timeline }: ScrollerElementAnimationEvent) {
        const parentRect = this.$el.getBoundingClientRect()
        const imageRect = this.$refs.image.getBoundingClientRect()

        timeline.fromTo(
            this.$refs.image,
            {
                x: parentRect.x - imageRect.x
            },
            {
                x: 0,
                duration: 1
            },
            0
        )
    }

    goToSlide(index: number): void {
        this.$emit('go-to-slide', index)
    }
}
